<template>
  <div class="container-xs">
    <AppBackHeader to="/search/summary">PAYMENT INFORMATION</AppBackHeader>
    <div style="display: grid; gap: 2rem; margin-top: 3rem">
      <div v-if="paymentMethods.length" style="display: grid; gap: 1rem">
        <PaymentCard
          v-for="payment in paymentMethods"
          :key="payment.id"
          :payment="payment"
          :data-selected="payment.id === paymentId"
          @click="selectPayment(payment.id)"
        />
      </div>
      <div style="display: grid; gap: 1rem">
        <AppLabel label="ADD NEW PAYMENT METHOD" />
        <AddPaymentCard @click="$router.push('/search/payments/add')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBackHeader from '../../../components-rf/AppBackHeader.vue'
import AppLabel from '../../../components-rf/AppLabel.vue'
import AddPaymentCard from '../../../components-rf/payments/AddPaymentCard.vue'
import PaymentCard from '../../../components-rf/payments/PaymentCard.vue'

export default {
  name: 'TripPayments',
  components: {
    AppBackHeader,
    AppLabel,
    AddPaymentCard,
    PaymentCard,
  },
  computed: {
    ...mapState('UserModule', ['paymentMethods']),
    ...mapState('SearchState', ['paymentId']),
  },
  methods: {
    selectPayment(id) {
      this.$store.commit('SearchState/setPaymentId', id)
      this.$router.push('/search/summary')
    },
  },
}
</script>

<style scoped lang="scss"></style>
